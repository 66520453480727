// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconBrush, IconTools } from '@tabler/icons';

// constant
const icons = {
    IconBrush,
    IconTools
};

// ==============================|| UI ELEMENTS MENU ITEMS ||============================== //

const elements = {

};

export default elements;
