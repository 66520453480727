// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = ({w,h}) => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */

        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width={w} height={h}  viewBox="0 0 200.000000 96.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
               fill="#000000" stroke="none">
                <path d="M570 849 c-30 -12 -40 -26 -40 -60 0 -31 20 -50 68 -64 44 -13 55
-35 17 -35 -14 0 -35 5 -46 11 -15 7 -24 7 -35 -2 -21 -17 -17 -27 16 -44 45
-23 97 -19 125 10 45 44 26 82 -50 107 -22 7 -38 18 -35 23 6 11 37 12 62 1
25 -11 45 30 21 42 -31 17 -77 21 -103 11z"/>
                <path d="M712 748 l3 -103 27 -3 c26 -3 27 -2 30 55 l3 58 20 -32 c10 -18 23
-33 27 -33 5 0 19 17 31 38 l22 37 3 -62 c3 -59 4 -63 27 -63 25 0 25 0 25
105 l0 105 -29 0 c-23 0 -34 -8 -51 -35 -24 -39 -33 -38 -55 6 -13 24 -22 29
-51 29 l-35 0 3 -102z"/>
                <path d="M1017 818 c-8 -18 -26 -61 -41 -95 -14 -34 -26 -67 -26 -73 0 -19 49
-11 60 10 8 15 21 20 50 20 29 0 42 -5 50 -20 11 -20 60 -29 60 -11 0 5 -18
51 -39 102 -36 88 -41 94 -70 97 -26 3 -32 -1 -44 -30z m63 -80 c0 -4 -9 -8
-21 -8 -15 0 -19 4 -15 16 3 9 6 21 6 27 1 13 30 -22 30 -35z"/>
                <path d="M1180 761 c0 -106 4 -121 36 -121 21 0 24 5 24 35 0 24 5 35 14 35 8
0 24 -16 36 -35 17 -27 28 -35 51 -35 35 0 36 8 8 44 l-21 27 21 23 c26 28 27
70 1 96 -16 16 -33 20 -95 20 l-75 0 0 -89z m125 14 c0 -15 -8 -21 -32 -23
-30 -3 -33 -1 -33 23 0 24 3 26 33 23 24 -2 32 -8 32 -23z"/>
                <path d="M1380 825 c0 -21 5 -25 30 -25 l30 0 0 -81 0 -80 28 3 c27 3 27 4 30
81 l3 77 29 0 c25 0 30 4 30 25 0 25 -1 25 -90 25 -89 0 -90 0 -90 -25z"/>
                <path d="M90 670 l0 -30 185 0 185 0 0 30 0 30 -185 0 -185 0 0 -30z"/>
                <path d="M443 571 c-45 -28 -61 -85 -38 -134 16 -34 64 -67 99 -67 8 0 34 7
56 16 39 16 40 17 40 65 l0 49 -50 0 c-43 0 -50 -3 -50 -20 0 -13 7 -20 20
-20 24 0 27 -28 4 -37 -24 -9 -63 14 -70 41 -12 50 39 91 84 67 15 -8 23 -6
36 8 15 16 15 19 1 31 -27 23 -97 23 -132 1z"/>
                <path d="M684 581 c-60 -26 -83 -114 -44 -164 65 -82 200 -38 200 64 0 74 -87
130 -156 100z m74 -57 c24 -17 30 -62 10 -82 -7 -7 -24 -12 -38 -12 -33 0 -50
17 -50 51 0 29 23 59 43 59 7 0 23 -7 35 -16z"/>
                <path d="M904 571 c-43 -26 -60 -71 -45 -120 23 -77 122 -103 180 -47 38 36
46 74 27 119 -26 62 -102 85 -162 48z m96 -51 c37 -37 16 -92 -35 -92 -30 0
-55 24 -55 54 0 22 34 58 55 58 8 0 24 -9 35 -20z"/>
                <path d="M1097 583 c-4 -3 -7 -51 -7 -106 l0 -99 76 4 c69 3 79 6 100 31 50
58 21 146 -54 167 -42 11 -105 13 -115 3z m121 -65 c7 -7 12 -24 12 -38 0 -34
-17 -50 -52 -50 -27 0 -28 2 -28 50 0 48 1 50 28 50 15 0 33 -5 40 -12z"/>
                <path d="M1347 423 c-4 -3 -7 -15 -7 -25 0 -17 15 -18 285 -18 l285 0 0 25 0
25 -278 0 c-153 0 -282 -3 -285 -7z"/>
                <path d="M387 233 c-55 -133 -53 -123 -19 -123 22 0 32 6 37 20 9 29 85 29 92
0 4 -15 14 -20 38 -20 l34 0 -46 105 c-42 95 -48 105 -72 105 -24 0 -30 -9
-64 -87z m73 -2 c0 -5 3 -16 6 -25 4 -12 0 -16 -16 -16 -16 0 -20 4 -16 16 3
9 6 20 6 25 0 5 5 9 10 9 6 0 10 -4 10 -9z"/>
                <path d="M590 216 l0 -106 80 0 c79 0 80 0 80 25 0 23 -3 25 -50 25 l-49 0 -3
78 c-3 76 -3 77 -30 80 l-28 3 0 -105z"/>
                <path d="M770 215 l0 -105 80 0 c79 0 80 0 80 25 0 23 -3 25 -50 25 l-50 0 0
80 0 80 -30 0 -30 0 0 -105z"/>
                <path d="M960 216 l0 -106 28 0 27 0 -2 102 c-1 103 -1 103 -27 106 l-26 3 0
-105z"/>
                <path d="M1086 228 c-21 -51 -41 -99 -43 -105 -9 -22 51 -15 65 7 17 28 75 27
93 -1 10 -14 22 -20 40 -17 l26 3 -43 100 c-38 91 -45 100 -71 103 -27 2 -30
-2 -67 -90z m84 -25 c0 -7 -9 -13 -20 -13 -23 0 -24 4 -9 32 9 17 13 18 20 7
5 -8 9 -20 9 -26z"/>
                <path d="M1290 215 l0 -105 29 0 c29 0 30 2 33 52 l3 52 40 -52 c31 -41 45
-52 67 -52 l29 0 -3 103 -3 102 -27 3 c-26 3 -27 2 -30 -52 l-3 -56 -42 55
c-32 41 -49 55 -68 55 l-25 0 0 -105z"/>
                <path d="M1571 307 c-49 -29 -67 -110 -35 -156 36 -51 123 -61 161 -19 17 19
17 20 0 33 -14 10 -25 10 -48 2 -49 -19 -79 12 -65 68 8 30 36 39 70 24 22
-11 29 -10 44 4 15 16 15 18 0 33 -22 22 -97 28 -127 11z"/>
                <path d="M1740 215 l0 -105 85 0 c84 0 85 0 85 25 0 24 -3 25 -55 25 -42 0
-55 3 -55 15 0 11 12 15 51 15 47 0 50 2 47 23 -3 19 -10 22 -50 25 -34 2 -48
7 -48 18 0 10 14 14 55 14 52 0 55 1 55 25 0 25 -1 25 -85 25 l-85 0 0 -105z"/>
                <path d="M90 135 l0 -25 100 0 100 0 0 25 0 25 -100 0 -100 0 0 -25z"/>
            </g>
        </svg>




    );
};

export default Logo;
