// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {IconBackspace, IconDashboard, IconDeviceAnalytics, IconHeadphones, IconHeart , IconPhoneIncoming, IconAffiliate} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconHeadphones,
    IconHeart,
    IconPhoneIncoming,
    IconAffiliate
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const callcenter = {
    id: 'callcenter',
    title: <FormattedMessage id="Call center" />,
    type: 'group',
    children: [
        {
        id: 'listcall',
        title: <FormattedMessage id="Appel en cours" />,
        type: 'item',
        url: '/callcenter/list',
        icon: icons.IconPhoneIncoming,
        breadcrumbs: false
         },

        {
            id: 'params',
            title: <FormattedMessage id="Paramètres" />,
            type: 'item',
            url: '/callcenter/params',
            icon: icons.IconAffiliate,
            breadcrumbs: false
        },

    ]
};

export default callcenter;
