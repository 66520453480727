// third-party
import { createSlice } from '@reduxjs/toolkit'

// project imports
import axios from 'axios'
import { dispatch } from '../index'
const URL_API =
    process.env.REACT_APP_NODE_ENV === 'production' ? 'https://api.smartalliance.fr/api' : 'http://localhost:4001/api'

// ----------------------------------------------------------------------

const initialState = {
    list: [],
    user: null,
}

const slice = createSlice({
    error: null,
    name: 'accounts',
    initialState,
    reducers: {
        hasError(state, error) {
            state.calls.error = error
        },

        getAccountsSuccess(state, action) {
            state.accounts = { ...state.accounts, list: action.payload }
        },

        getAccountSuccess(state, action) {
            state = { ...state, current: action.payload }
        },

        getUserById(state, action) {
            state.user = action.payload
        },
    },
})

// Reducer
export default slice.reducer

export function getOneAccount(id) {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/account?id=${id}`)
            dispatch(slice.actions.getAccountSuccess(response.data))
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    }
}

export function getListAccount() {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/accounts`)
            dispatch(slice.actions.getAccountsSuccess(response.data))
        } catch (error) {
            console.log('error', error)
            // dispatch(slice.actions.hasError(error));
        }
    }
}

export function getUserById(id) {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/user/${id}`)
            console.log('dispatch', response.data)
            dispatch(slice.actions.getUserById(response.data))
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    }
}
