// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {IconUsers, IconDashboard, IconDeviceAnalytics, IconHeadphones, IconHeart , IconPhoneIncoming, IconAffiliate} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconHeadphones,
    IconHeart,
    IconPhoneIncoming,
    IconAffiliate,
    IconUsers
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const account = {
    id: 'users',
    title: <FormattedMessage id="Utilisateurs" />,
    type: 'group',
    children: [
        {
            id: 'userlist',
            title: <FormattedMessage id="liste des utilisateurs" />,
            type: 'item',
            url: '/account/list',
            icon: icons.IconUsers,
            breadcrumbs: false
        },


    ]
};

export default account;
