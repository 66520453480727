import jwtDecode from "jwt-decode";
import PropTypes from "prop-types";
import JWTContext, {JWTProvider} from "./contexts/JWTContext";
import {useEffect} from "react";

import {notif_Call} from "./store/slices/call";
import {useDispatch, useSelector} from "./store";

const W3CWebSocket = require('websocket').w3cwebsocket;

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

export const Wss = ({ children }) => {

    const dispatch = useDispatch();



    useEffect(()=>{
        const serviceToken = window.localStorage.getItem('serviceToken');

        try {
            if (serviceToken && verifyToken(serviceToken)) {

                const client = new W3CWebSocket('wss://api.smartalliance.fr/ws?token=' + serviceToken);
                console.log("INIT Login", 'wss://api.smartalliance.fr/ws?token=' + serviceToken)

                client.onopen = () => {
                    console.log('WebSocket Client Connected');
                };

                client.onmessage = (message) => {

                    const mes = JSON.parse(message.data)
                    if(mes.type === "newcall"){
                        dispatch(notif_Call(mes.message))
                    }

                };
                client.onerror = function (error) {
                    console.log('Connection Error', error);
                };

            }
        }catch (e){
            console.log("CATCH Error", e)
        }


    },[])

    return (
        <div>{children}</div>
    );

}

Wss.propTypes = {
    children: PropTypes.node
};

export default Wss;
