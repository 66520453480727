// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {IconUsers, IconDashboard, IconDeviceAnalytics, IconHeadphones, IconHeart , IconPhoneIncoming, IconAffiliate} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconDeviceAnalytics,
    IconHeadphones,
    IconHeart,
    IconPhoneIncoming,
    IconAffiliate,
    IconUsers
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const aine = {
    id: 'aine',
    title: <FormattedMessage id="Ainés" />,
    type: 'group',
    children: [
        {
            id: 'listaine',
            title: <FormattedMessage id="liste des ainés" />,
            type: 'item',
            url: '/aine/list',
            icon: icons.IconUsers,
            breadcrumbs: false
        },


    ]
};

export default aine;
