// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
const URL_API = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://api.smartalliance.fr' : 'http://localhost:4001';


console.log('URL', {URL_API})
// ----------------------------------------------------------------------

const initialState = {
    list: [],
    current:false,
    ctrlemail:null,
    services: [],
    classements:[],
    notif_call:false,
    rountings:[],
    rounting:false
};

const slice = createSlice({
    error:null,
    name: 'calls',
    initialState,
    reducers: {

        hasError(state,  error){
            state.calls.error = error;
        },

        getOneCallSuccess(state, action){
            state.calls = {...state.calls, current:action.payload}
        },

        checkEmailSuccess(state, action){
             state.calls = {...state.calls, ctrlemail:action.payload}
        },

        addClassementSuccess(state, action){
             state.calls = {...state.calls, classements:action.payload}
        },
        getClassementSuccess(state, action) {
            state.calls = {...state.calls, classements:action.payload}
        },

        getCallSuccess(state, action) {
            state.calls = {...state.calls, list:action.payload}
        },

        getNotifSuccess(state, action) {
            state.calls = {...state.calls, notif_call:action.payload}
        },
        getRountingSuccess(state, action) {
            state.calls = {...state.calls, rountings:action.payload}
        },
        getRountingOneSuccess(state, action) {
            state.calls = {...state.calls, rounting:action.payload}
        },
        getTypologyServices(state, action) {
            state.calls = {...state.calls, services:action.payload}
        },
        getSources(state, action) {
            state.calls = {...state.calls, sources:action.payload}
        },
        getSourcesFromAllCalls(state, action) {
            state.calls = {...state.calls, sourcesAll:action.payload}
        },
        getServicesFromAllCalls(state, action) {
            state.calls = {...state.calls, servicesAll:action.payload}
        },
        getServicesOccurences(state, action) {
            state.calls = {...state.calls, servicesOccurences:action.payload}
        },
        getSourcesOccurences(state, action) {
            state.calls = {...state.calls, sourcesOccurences:action.payload}

        }

    }
});

// Reducer
export default slice.reducer;

/** Verif Email  Element **/

export function ctrlEmail(email, callID){
    return async () => {
        try {
            const response = await axios.post(`${URL_API}/api/ctrlemail`,{email});
            console.log({callID})
            dispatch(slice.actions.checkEmailSuccess({...response.data, callID}));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}


/** Call Element **/

export function majCall(val, id){
    return async () => {
        try {
            const response = await axios.post(`${URL_API}/api/call?id=${id}`, val);
            dispatch(slice.actions.getOneCallSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function getOneCall(id){
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call?id=${id}`);
            dispatch(slice.actions.getOneCallSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function getListCall() {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/calls`);
             dispatch(slice.actions.getCallSuccess(response.data));
        } catch (error) {
           console.log(error)
        }
    };
}


export function notif_Call(mes){
    return async () => {
        try {

            dispatch(slice.actions.getNotifSuccess(mes));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}

/** Ranking Element **/


export function listClassementBO(){
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call/classement`);

            dispatch(slice.actions.getClassementSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };

}

export function addClassementBO(element){
    return async () => {
        try {
            const response = await axios.post(`${URL_API}/api/call/classement`,element);
            dispatch(slice.actions.addClassementSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };

}


/** Routing Element **/

export function listRountingBO(){
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call/routing`);
            dispatch(slice.actions.getRountingSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };

}

export function addRoutingBO(element){
    return async () => {
        try {
            const response = await axios.post(`${URL_API}/api/call/routing`,element);
            dispatch(slice.actions.getRountingSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}


export function OneRountingBO(id){
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call/routingone?id=${id}`);
            dispatch(slice.actions.getRountingOneSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };

}

export function UpdateroutingBO(val,id,type){
    return async () => {
        try {
            const response = await axios.put(`${URL_API}/api/call/routing?id=${id}&type=${type}`,val);

           if(val.selected){
               dispatch(slice.actions.getRountingSuccess(response.data));
           }else{
               dispatch(slice.actions.getRountingOneSuccess(response.data));

           }
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };

}


export function UpdateroutingBOFile(files,obj, pos){

    console.log("FILE IN REST API" )

    console.log(files)
    console.log("OBJ IN REST API" , obj)

    return async () => {
        try {
            const formData = new FormData();
            formData.append(
                "sound",
                files[0].content,
                obj.routing._id+".mp3"
                //obj.files[0].content.name
            );
             const response = await axios.put(`${URL_API}/api/call/routingfile?type=file&pos=${pos}&id=${obj.routing._id}`,formData);
            dispatch(slice.actions.getRountingOneSuccess(response.data));
        } catch (error) {
            console.log("error API", error)
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateTypologyServices(parameters) {
    return async () => {
        try {
            const response = await axios.put(`${URL_API}/api/call/typology`,parameters);
            dispatch(slice.actions.getTypologyServices(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}
export function getTypologyServices() {
    console.log(`before async`)
    return async () => {
        try {
            console.log(`in try`, `${URL_API}/services/typologies`)
            const response = await axios.get(`${URL_API}/api/services/typologies`);

            //const response = await axios.get(`${URL_API}/ping`);
            console.log({response})
            dispatch(slice.actions.getTypologyServices(response.data));
        } catch (error) {
            console.log(`Axios error`, error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSources() {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/sources`);
            dispatch(slice.actions.getSources(response.data));
        } catch (error) {
            console.log(`Axios error`, error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function updateCallServices(callID, services) {
    return async () => {
        try {
            const response = await axios.put(`${URL_API}/api/call/services/typologies?callID=${callID}`, {
                callID,
                services
            });
          //  dispatch(slice.actions.getTypologyServices(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}
export function updateCallSources(callID, sources) {
    return async () => {
        try {
            const response = await axios.put(`${URL_API}/api/call/sources?callID=${callID}`, {
                callID,
                sources
            });
        } catch (error) {
            console.log(error)
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getSourcesFromAllCalls() {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call/sources`);
            dispatch(slice.actions.getSourcesFromAllCalls(response.data));
        } catch (error) {
            console.log(`Axios error`, error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function getServicesFromAllCalls() {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call/services/typologies`);
            dispatch(slice.actions.getServicesFromCalls(response.data));
        } catch (error) {
            console.log(`Axios error`, error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getServicesOccurences() {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call/services/typologies/occurences`);
            dispatch(slice.actions.getServicesOccurences(response.data));
        } catch (error) {
            console.log(`Axios error`, error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
export function getSourcesOccurences() {
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/api/call/sources/occurences`);
            dispatch(slice.actions.getSourcesOccurences(response.data));
        } catch (error) {
            console.log(`Axios error`, error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


/*
app.get(`/api/call/services/typologies`, getServicesFromCalls);
*/
