// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'axios';
import { dispatch } from '../index';
const URL_API = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://api.smartalliance.fr' : 'http://localhost:4001/api';
// ----------------------------------------------------------------------

const initialState = {
    list: [],

};

const slice = createSlice({
    error:null,
    name: 'aines',
    initialState,
    reducers: {

        hasError(state,  error){
            state.calls.error = error;
        },

        getAinesSuccess(state, action){

            state.aines = {...state.aines, list:action.payload}
        },

        getAineSuccess(state, action){
            state = {...state, current:action.payload}
        }


    }
});

// Reducer
export default slice.reducer;



export function getOneAine(id){
    return async () => {
        try {
            const response = await axios.get(`${URL_API}/aine?id=${id}`);
            dispatch(slice.actions.getAineSuccess(response.data));
        } catch (error) {
            console.log(error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}

export function getListAine() {
    return async () => {
        try {

            const response = await axios.get(`${URL_API}/aines`);
            dispatch(slice.actions.getAinesSuccess(response.data));
        } catch (error) {
            console.log("error", error)
            // dispatch(slice.actions.hasError(error));
        }
    };
}







