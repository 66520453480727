import dashboard from './dashboard';
import widget from './widget';
import application from './application';
import forms from './forms';
import elements from './elements';
import pages from './pages';
import utilities from './utilities';
import support from './support';
import other from './other';
import callcenter from "./callcenter";
import account from "./account";
import aine from "./aine";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, callcenter, account,aine ]
};

export default menuItems;
