import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import jwtDecode from 'jwt-decode';

// reducer - state management
import {LOGIN, LOGOUT, ACTIVATED} from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
//import axios from 'utils/axios';
import axios from 'axios';
//import {notif_Call} from "../store/slices/call";


const URL_API = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://api.smartalliance.fr' : 'http://localhost:4001';
const chance = new Chance();

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    ctrlemail:null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {

    //console.log("SET SESSION Service Token", serviceToken)
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

//const W3CWebSocket = require('websocket').w3cwebsocket;


export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                console.log("INIT Login", serviceToken)

                if (serviceToken && verifyToken(serviceToken)) {

                    console.log("INIT Login Token OK")

                    setSession(serviceToken);
                    console.log("INIT Login Token setSession OK")

                    const response = await axios.get(URL_API+'/api/me');
                    console.log("INIT Login Token response OK", response)

                    const { user } = response.data;
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user
                        }
                    });

                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error("Error",err);
                dispatch({
                    type: LOGOUT
                });
            }
        };

        init();
    }, []);


    const resetPasswordAuth = async (key, password)=>{

       // const response = await axios.post(URL_API+'/api/resetpasswordauth', { key, password });

    }




    const login = async (email, password) => {
        const response = await axios.post(URL_API+'/api/signin', { email, password });
        const { token } = response.data;

        setSession(token);






        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true,
                user:response.data
            }
        });
    };


    const activatedAccount =  async (key) => {

        const response = await axios.get(URL_API+'/api/activatedaccount?key='+key);
       // setSession(null);

        console.log("response.data", response.data)
        if(response.data){
             dispatch({
                type: ACTIVATED,
                payload: {
                    isLoggedIn: false,
                    user:{activated:true}

                }
            });
        }

    }

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const id = chance.bb_pin();
        const response = await axios.post(URL_API+'/api/signup', {
            id,
            email,
            password,
            name:firstName,
            surname:lastName
        });
        let users = response.data;


    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email) => {


/*
        const response = await axios.post(URL_API+'/api/resetpassword', {
            email
        });
      //  let users = response.data;
*/
    }

    const updateProfile = () => {};

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPasswordAuth, resetPassword, updateProfile, activatedAccount }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
